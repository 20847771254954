import React, { useState } from "react";

const TimeTable = () => {
  const [pdfFile, setPdfFile] = useState(null);

  const handlePdfUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      const fileURL = URL.createObjectURL(file);
      setPdfFile(fileURL); // Create a URL for the selected PDF file
    } else {
      alert("Please upload a valid PDF file.");
    }
  };

  return (
    <div className="p-6">
      {/* Header Section */}
      <div className="sm:flex sm:items-center mb-11">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Time Table Management
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            At this section you can manage your time table.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <label className="block rounded-md bg-sky-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600">
            Upload PDF
            <input
              type="file"
              accept="application/pdf"
              className="hidden"
              onChange={handlePdfUpload}
            />
          </label>
        </div>
      </div>

      {/* Display PDF */}
      {pdfFile ? (
        <div className="flex justify-center items-center">
          <iframe
            src={pdfFile}
            title="Time Table PDF"
            className="w-full h-screen"
          ></iframe>
        </div>
      ) : (
        <p className="text-center text-gray-500">
          No PDF uploaded. Please upload a PDF to view it here.
        </p>
      )}
    </div>
  );
};

export default TimeTable;
