// src/components/UserRequestCard.js
import React from "react";

const UserRequestCard = ({ request }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 mb-4 border border-gray-200">
      <h3 className="text-lg font-semibold text-gray-800">Request Details</h3>
      <p className="mt-2 text-sm">
        <strong>Type:</strong> {request.requestType}
      </p>
      <p className="mt-2 text-sm">
        <strong>Status:</strong>{" "}
        {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
      </p>
      <p className="mt-2 text-sm">
        <strong>Additional Info:</strong> {request.additionalInfo || "N/A"}
      </p>
      <p className="mt-2 text-sm">
        <strong>Start Date:</strong>{" "}
        {new Date(request.startDate).toLocaleDateString() || "N/A"}
      </p>
      <p className="mt-2 text-sm">
        <strong>End Date:</strong>{" "}
        {new Date(request.endDate).toLocaleDateString() || "N/A"}
      </p>
    </div>
  );
};

export default UserRequestCard;
