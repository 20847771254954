// src/pages/UserDetails.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUserById } from "../api/user";
import UserRequestCard from "../components/UserRequestCard"; // Updated import

const UserDetails = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const data = await getUserById(id);
        setUser(data);
      } catch (error) {
        console.error("Error fetching user:", error);
        setError("Could not fetch user details.");
      }
    };
    if (id) fetchUser();
  }, [id]);

  if (error) return <p>{error}</p>;
  if (!user) return <p>Loading...</p>;

  return (
    <div className="max-w-3xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6">User Details</h1>
      <div className="bg-white shadow p-4 rounded-lg mb-6">
        <p>
          <strong>Name:</strong> {user.fullName}
        </p>
        <p>
          <strong>Email:</strong> {user.email}
        </p>
        <p>
          <strong>Civil ID:</strong> {user.civilID}
        </p>
        <p>
          <strong>Role:</strong> {user.role}
        </p>
        <p>
          <strong>Center:</strong> {user.center || "N/A"}
        </p>
        <p>
          <strong>Team Leader:</strong> {user.teamLeader || "N/A"}
        </p>
        <p>
          <strong>Phone:</strong> {user.phone || "N/A"}
        </p>
        <p>
          <strong>Address:</strong> {user.address || "N/A"}
        </p>
      </div>

      <h2 className="text-xl font-semibold mb-4">Requests</h2>
      {user.requests && user.requests.length > 0 ? (
        user.requests.map((request) => (
          <UserRequestCard key={request._id} request={request} />
        ))
      ) : (
        <p className="text-gray-500">No requests found.</p>
      )}
    </div>
  );
};

export default UserDetails;
