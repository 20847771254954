import instance from ".";

//get all users
export const getUsers = async () => {
  const { data } = await instance.get("/api/auth/users");
  return data;
};

//get user by id
export const getUserById = async (id) => {
  const { data } = await instance.get(`/api/auth/users/${id}`);
  return data;
};
