// import React, { useContext, useEffect, useState } from "react";
// import { PhotoIcon } from "@heroicons/react/24/solid";
// import UserContext from "../context/UserContext";

// export default function FileUploadForm() {
//   const [user] = useContext(UserContext);
//   const [userInfo, setUserInfo] = useState({
//     civilID: "",
//     fullName: "",
//     center: "",
//     team_leader: "",
//   });

//   // Prepopulate form with user data
//   useEffect(() => {
//     if (user) {
//       setUserInfo({
//         civilID: user.civilID || "",
//         fullName: user.fullName || "",
//         center: user.center || "",
//         team_leader: user.teamLeader || "",
//       });
//     }
//   }, [user]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setUserInfo({ ...userInfo, [name]: value });
//   };

//   return (
//     <form>
//       <div className="space-y-12">
//         <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
//           <div>
//             <h2 className="text-base text-left font-semibold leading-7 text-gray-900">
//               Upload File Form
//             </h2>
//             <p className="mt-1 text-sm leading-6 text-gray-600">
//               This information will be displayed publicly so be careful what you
//               share.
//             </p>
//           </div>

//           <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
//             <div className="sm:col-span-4">
//               <label
//                 htmlFor="civilID"
//                 className="block text-left text-sm font-medium leading-6 text-gray-900"
//               >
//                 Civil ID
//               </label>
//               <div className="mt-2">
//                 <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
//                   <input
//                     id="civilID"
//                     name="civilID"
//                     type="text"
//                     value={userInfo.civilID}
//                     onChange={handleChange}
//                     placeholder="1234567890"
//                     className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
//                   />
//                 </div>
//               </div>
//             </div>

//             <div className="sm:col-span-4">
//               <label
//                 htmlFor="fullName"
//                 className="block text-left text-sm font-medium leading-6 text-gray-900"
//               >
//                 Name
//               </label>
//               <div className="mt-2">
//                 <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
//                   <input
//                     id="fullName"
//                     name="fullName"
//                     type="text"
//                     value={userInfo.fullName}
//                     onChange={handleChange}
//                     placeholder="John Doe"
//                     className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
//                   />
//                 </div>
//               </div>
//             </div>

//             <div className="sm:col-span-4">
//               <label
//                 htmlFor="center"
//                 className="block text-left text-sm font-medium leading-6 text-gray-900"
//               >
//                 Center
//               </label>
//               <div className="mt-2">
//                 <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
//                   <input
//                     id="center"
//                     name="center"
//                     type="text"
//                     value={userInfo.center}
//                     onChange={handleChange}
//                     placeholder="Center Name"
//                     className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
//                   />
//                 </div>
//               </div>
//             </div>

//             <div className="sm:col-span-4">
//               <label
//                 htmlFor="team_leader"
//                 className="block text-left text-sm font-medium leading-6 text-gray-900"
//               >
//                 Team Leader
//               </label>
//               <div className="mt-2">
//                 <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
//                   <input
//                     id="team_leader"
//                     name="team_leader"
//                     type="text"
//                     value={userInfo.team_leader}
//                     onChange={handleChange}
//                     placeholder="Team Leader Name"
//                     className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
//                   />
//                 </div>
//               </div>
//             </div>

//             <div className="sm:col-span-3">
//               <label
//                 htmlFor="request"
//                 className="block text-left text-sm font-medium leading-6 text-gray-900"
//               >
//                 File Type
//               </label>
//               <div className="mt-2">
//                 <select
//                   id="request"
//                   name="request"
//                   autoComplete="Request-type"
//                   className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:max-w-xs sm:text-sm sm:leading-6"
//                 >
//                   <option>Test 1</option>
//                   <option>Test 2</option>
//                   <option>Test 3</option>
//                   <option>Test 4</option>
//                   <option>Other</option>
//                 </select>
//               </div>
//             </div>

//             <div className="col-span-full">
//               <label
//                 htmlFor="cover-photo"
//                 className="block text-left text-sm font-medium leading-6 text-gray-900"
//               >
//                 Upload your file
//               </label>
//               <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
//                 <div className="text-center">
//                   <PhotoIcon
//                     aria-hidden="true"
//                     className="mx-auto h-12 w-12 text-gray-300"
//                   />
//                   <div className="mt-4 flex text-sm leading-6 text-gray-600">
//                     <label
//                       htmlFor="file-upload"
//                       className="relative cursor-pointer rounded-md bg-white font-semibold text-sky-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-sky-600 focus-within:ring-offset-2 hover:text-sky-500"
//                     >
//                       <span>Upload a file</span>
//                       <input
//                         id="file-upload"
//                         name="file-upload"
//                         type="file"
//                         className="sr-only"
//                       />
//                     </label>
//                     <p className="pl-1">or drag and drop</p>
//                   </div>
//                   <p className="text-xs leading-5 text-gray-600">
//                     PNG, JPG, GIF up to 10MB
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="mt-6 flex items-center justify-end gap-x-6">
//         <button
//           type="button"
//           className="text-sm font-semibold leading-6 text-gray-900"
//         >
//           Cancel
//         </button>
//         <button
//           type="submit"
//           className="rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
//         >
//           Send
//         </button>
//       </div>
//     </form>
//   );
// }
import React, { useEffect, useState } from "react";
import { PhotoIcon } from "@heroicons/react/24/solid";
import { fetchUserData } from "../api/auth";

export default function FileUploadForm() {
  const [userInfo, setUserInfo] = useState({
    civilID: "",
    fullName: "",
    center: "",
    teamLeader: "",
  });

  useEffect(() => {
    const getUserData = async () => {
      try {
        const data = await fetchUserData();
        setUserInfo({
          civilID: data.civilID || "",
          fullName: data.fullName || "",
          center: data.center || "",
          teamLeader: data.teamLeader || "",
        });
      } catch (error) {
        console.error("Failed to fetch user data:", error.message);
      }
    };

    getUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  // const [file, setFile] = useState(null);

  // const handleFileChange = (e) => {
  //   setFile(e.target.files[0]);
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   formData.append("name", userInfo.fullName);
  //   formData.append("center", userInfo.center);

  //   try {
  //     const response = await instance.post("/api/upload", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });
  //     console.log(response.data);
  //   } catch (error) {
  //     console.error("Error uploading file:", error.message);
  //   }
  // };

  return (
    <form>
      <div className="space-y-12">
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base text-left font-semibold leading-7 text-gray-900">
              Upload File Form
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              This information will be displayed publicly, so be careful what
              you share.
            </p>
          </div>

          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-4">
              <label
                htmlFor="civilID"
                className="block text-left text-sm font-medium leading-6 text-gray-900"
              >
                Civil ID
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
                  <input
                    id="civilID"
                    name="civilID"
                    type="text"
                    value={userInfo.civilID}
                    readOnly
                    className="block flex-1 border-0 bg-gray-100 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="sm:col-span-4">
              <label
                htmlFor="fullName"
                className="block text-left text-sm font-medium leading-6 text-gray-900"
              >
                Full Name
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
                  <input
                    id="fullName"
                    name="fullName"
                    type="text"
                    value={userInfo.fullName}
                    readOnly
                    className="block flex-1 border-0 bg-gray-100 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="sm:col-span-4">
              <label
                htmlFor="center"
                className="block text-left text-sm font-medium leading-6 text-gray-900"
              >
                Center
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
                  <input
                    id="center"
                    name="center"
                    type="text"
                    value={userInfo.center}
                    onChange={handleChange}
                    placeholder="Center Name"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="sm:col-span-4">
              <label
                htmlFor="teamLeader"
                className="block text-left text-sm font-medium leading-6 text-gray-900"
              >
                Team Leader
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
                  <input
                    id="teamLeader"
                    name="teamLeader"
                    type="text"
                    value={userInfo.teamLeader}
                    onChange={handleChange}
                    placeholder="Team Leader Name"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-full">
              <label
                htmlFor="file-upload"
                className="block text-left text-sm font-medium leading-6 text-gray-900"
              >
                Upload your file
              </label>
              <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                <div className="text-center">
                  <PhotoIcon
                    aria-hidden="true"
                    className="mx-auto h-12 w-12 text-gray-300"
                  />
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-sky-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-sky-600 focus-within:ring-offset-2 hover:text-sky-500"
                    >
                      <span>Upload a file</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs leading-5 text-gray-600">
                    PNG, JPG, GIF up to 10MB
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
        >
          Send
        </button>
      </div>
    </form>
  );
}
