import React, { useEffect } from "react";
import announcementIcon from "../assets/timetable.png";
import requestIcon from "../assets/NewRequest.jpg";
import fileUploadIcon from "../assets/FileUpLode.png";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  const isAuthenticated = () => {
    return !!localStorage.getItem("token");
  };

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/register");
    }
  }, [navigate]);

  const handleAnnouncementsClick = () => {
    navigate("/timeTable");
  };

  const handleRequestClick = () => {
    navigate("/request");
  };

  const handleFileUploadClick = () => {
    navigate("/fileUpload");
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
      {/* Main Container for Sections */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-20">
        {/* Announcements & Timetable */}
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={handleAnnouncementsClick}
        >
          <img
            src={announcementIcon}
            alt="Announcements & Timetable"
            className="w-32 h-32"
          />
          <p className="mt-4 text-center text-xl font-semibold">
            Announcements & Timetable
          </p>
        </div>

        {/* Request Section */}
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={handleRequestClick}
        >
          <img src={requestIcon} alt="Request" className="w-32 h-32" />
          <p className="mt-4 text-center text-xl font-semibold">Request</p>
        </div>
      </div>

      {/* File Upload Section */}
      <div
        className="mt-10 flex flex-col items-center cursor-pointer"
        onClick={handleFileUploadClick}
      >
        <img src={fileUploadIcon} alt="File Upload" className="w-32 h-32" />
        <p className="mt-4 text-center text-xl font-semibold">File Upload</p>
      </div>
    </div>
  );
};

export default Home;
