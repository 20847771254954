import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getRequestById, updateRequest } from "../api/request";
import { useMutation } from "@tanstack/react-query";

const statuses = ["pending", "approved", "rejected"];

const RequestDetails = () => {
  const { id } = useParams();
  const [status, setStatus] = useState("pending");
  const [note, setNote] = useState("");
  const [request, setRequest] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchRequest = async () => {
      try {
        const data = await getRequestById(id);
        setRequest(data);
      } catch (error) {
        console.error("Failed to fetch request:", error);
      }
    };
    fetchRequest();
  }, [id]);

  useEffect(() => {
    if (request) {
      setStatus(request.status || "pending");
      setNote(request.employeeNote || "");
    }
  }, [request]);


  const { mutate } = useMutation({
    mutationFn: updateRequest,
    onSuccess: () => {
      console.log("Request updated successfully");
      alert("Request updated successfully");
      navigate("/request");
    },
    onError: (error) => {
      console.error("Failed to update request:", error);
      alert("Failed to update request");
    },
  });

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-gray-800 opacity-75"></div>
      <div className="bg-white rounded-lg p-6 w-96 z-10">
        <h2 className="text-xl font-semibold mb-4">Request Details</h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Employee Name
          </label>
          <div className="mt-1">
            {request?.userId?.fullName || "Unknown User"}
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Start Date
          </label>
          <div className="mt-1">
            {request?.startDate
              ? request.startDate.slice(0, 10)
              : "Unknown Date"}
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            End Date
          </label>
          <div className="mt-1">
            {request?.endDate ? request.endDate.slice(0, 10) : "Unknown Date"}
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Request Type
          </label>
          <div className="mt-1">{request?.requestType || "N/A"}</div>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Status
          </label>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            {statuses.map((s) => (
              <option key={s} value={s}>
                {s.charAt(0).toUpperCase() + s.slice(1)}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Note
          </label>
          <textarea
            value={note}
            onChange={(e) => setNote(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="flex justify-end space-x-2">
          <button
            onClick={() => {
              navigate("/request");
            }}
            className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              mutate({ requestId: id, status, employeeNote: note });
            }}
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestDetails;
